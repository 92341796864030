<template>
  <v-select
    v-model="vModel"
    :items="data"
    :label="label"
    :rules="rules"
    :class="vclass"
    outlined
    dense
    hide-details="auto"
    v-bind="attrs"
    v-on="events"
  />
</template>
<script>
export default {
  props: {
    data: { type: [Array, Object], required: true, default: () => [] },
    property: { type: [Array, Object], required: true, default: () => [] },
    cvalue: {
      type: [Array, Object, String, Function, Boolean],
      required: false,
      default: () => 'Belum'
    },
    syncValue: {
      type: Function,
      default: (v) => v,
      required: true
    },
    rules: { type: [Array], required: false, default: () => [] },
    label: { type: [String, Boolean], required: false, default: () => '' },
    vclass: { type: String, required: false, default: () => '' }
  },
  created () {
    const { attrs, events } = this.property || {}
    this.attrs = attrs || {}
    this.events = events || {}
  },
  data () {
    return {
      vModel: null,
      attrs: {},
      events: {}
    }
  },
  watch: {
    vModel (n, o) {
      this.syncValue(n)
    },
    cvalue (n, o) {
      this.vModel = n
    }
  },
  methods: {
    test () {
      console.log('RUNNNNNNNN')
    }
  }
}
</script>
