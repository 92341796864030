<template>
  <div>
    <v-autocomplete
      v-model="vModel"
      return-object
      item-text="nama_keluhan"
      item-value="id_keluhan"
      multiple
      deletable-chips
      :cache-items="true"
      :disabled="isReadonly"
      placeholder="Start typing to Search"
      prepend-icon="mdi-database-search"
      :items="items"
      chips
      :search-input.sync="searchTindakan"
      :label="label"
    >
    </v-autocomplete>
    <!-- {{ vModel }} -->
    <div v-if="vModel">
      <v-chip
        v-for="(indexTindakan, i) of vModel"
        :key="i"
        class="ma-2"
        close
        color="primary"
        @click:close="deleteItem(i)"
        text-color="white"
      >
        {{ indexTindakan.nama_keluhan }}
      </v-chip>
    </div>
  </div>
</template>
<script>
import {
  // eslint-disable-next-line no-unused-vars
  apiKedua,
  // eslint-disable-next-line no-unused-vars
  successMsg,
  // eslint-disable-next-line no-unused-vars
  baseUrl,
  // eslint-disable-next-line no-unused-vars
  baseUrlV2,
  // eslint-disable-next-line no-unused-vars
  errorMsg
} from '@/plugins/supports'
export default {
  name: 'AutoComplete',
  props: {
    property: { type: [Array, Object], required: false, default: () => {} },
    cvalue: {
      type: [Array, Object, String, Function, Boolean],
      required: false,
      default: () => null
    },
    syncValue: {
      type: Function,
      default: (v) => v,
      required: true
    },
    rules: { type: [Array], required: false, default: () => [] },
    label: { type: [String, Boolean], required: false, default: () => '' },
    vclass: { type: String, required: false, default: () => '' }
  },
  created () {
    const { attrs, events } = this.property || {}
    this.attrs = attrs || {}
    this.events = events || {}
    this.isArray = this.vModel
  },
  data () {
    return {
      searchTindakan: '',
      modelicd: '',
      isReadonly: false,
      isLoading: false,
      isArray: true,
      vModel: null,
      dataVmodel: null,
      attrs: {},
      events: {},
      select: { state: 'Florida', abbr: 'FL' },
      items: []
    }
  },
  methods: {
    deleteItem (index) {
      // console.log('hantu' + index)
      this.vModel.splice(index, 1)
      // this.vModel.splice(index, 1)
    },
    cekInputan () {}
  },
  mounted () {
    // this.$emit('input', this.vModel)
    // console.log(this.vModel + 'ADA NGGAK ISINYA')
  },
  watch: {
    vModel (n, o) {
      this.syncValue(n)
    },
    cvalue (n, o) {
      // console.log(n + 'INI NGGAK ADA ISINYA ???')
      this.vModel = n
    },
    emitInput () {},
    searchTindakan (val) {
      if (val) {
        // eslint-disable-next-line no-undef
        $.get(
          baseUrl + 'igd/get-keluhan?q=' + val,
          (response) => {
          // console.log(response)
            this.items = response.results
          },
          'JSON'
        )
      }
    }
  }
}
</script>
