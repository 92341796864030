<template>
  <div>
    <v-textarea
      v-model="vModel"
      :label="label"
      :rules="rules"
      :class="vclass"
      outlined
      rows="4"
      auto-grow
      hide-details="auto"
      v-bind="attrs"
      v-on="events"
    />
<!--    <p class="mt-4">{{ label }}</p>-->
  </div>
</template>
<script>
export default {
  name: 'TextareaField',
  props: {
    property: { type: [Array, Object], required: false, default: () => {} },
    cvalue: {
      type: [Array, Object, String, Function, Boolean],
      required: false,
      default: () => null
    },
    syncValue: {
      type: Function,
      default: (v) => v,
      required: true
    },
    rules: { type: [Array], required: false, default: () => [] },
    label: { type: [String, Boolean], required: false, default: () => '' },
    vclass: { type: String, required: false, default: () => '' }
  },
  created () {
    const { attrs, events } = this.property || {}
    this.attrs = attrs || {}
    this.events = events || {}
  },
  data () {
    return {
      vModel: null,
      attrs: {},
      events: {}
    }
  },
  watch: {
    vModel (n, o) {
      this.syncValue(n)
    },
    cvalue (n, o) {
      this.vModel = n
    }
  }
}
</script>
