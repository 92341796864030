<template>
  <div>
    <v-autocomplete
      v-model="vModel"
      return-object
      item-text="text"
      item-value="id"
      hide-no-data
      hide-selected
      :cache-items="true"
      :disabled="isReadonly"
      placeholder="Cari Dokter DPJP"
      :rules="rules"
      outlined

      clearable
      dense
      :items="items"
      :search-input.sync="searchDokter"
      :label="label"
    >
    </v-autocomplete>

    <v-chip v-if="vModel" color="primary" text-color="white">
      {{ vModel.text }}
    </v-chip>
  </div>
</template>
<script>
import {
  // eslint-disable-next-line no-unused-vars
  apiKedua,
  // eslint-disable-next-line no-unused-vars
  successMsg,
  // eslint-disable-next-line no-unused-vars
  baseUrl,
  // eslint-disable-next-line no-unused-vars
  baseUrlV2,
  // eslint-disable-next-line no-unused-vars
  errorMsg
} from '@/plugins/supports'

export default {
  name: 'AutoCompleteSingle',
  props: {
    property: {
      type: [Array, Object],
      required: false,
      default: () => {}
    },
    cvalue: {
      type: [Array, Object, String, Function, Boolean],
      required: false,
      default: () => null
    },
    syncValue: {
      type: Function,
      default: (v) => v,
      required: true
    },
    rules: { type: [Array], required: false, default: () => [] },
    label: { type: [String, Boolean], required: false, default: () => '' },
    vclass: { type: String, required: false, default: () => '' }
  },
  created () {
    const { attrs, events } = this.property || {}
    this.attrs = attrs || {}
    this.events = events || {}
  },
  data () {
    return {
      searchDokter: '',
      modelicd: '',
      isReadonly: false,
      isLoading: false,
      vModel: null,
      dataVmodel: null,
      attrs: {},
      events: {},
      select: { state: 'Florida', abbr: 'FL' },
      items: []
    }
  },
  methods: {
    deleteItem (index) {
      // console.log('hantu' + index)
      this.vModel.splice(index, 1)
      // this.vModel.splice(index, 1)
    },
    cekInputan () {}
  },
  mounted () {
    // this.$emit('input', this.vModel)
    // console.log(this.vModel + 'ADA NGGAK ISINYA')
  },
  watch: {
    vModel (n, o) {
      this.syncValue(n)
    },
    cvalue (n, o) {
      // console.log(n + 'INI NGGAK ADA ISINYA ???')
      this.vModel = n
    },
    emitInput () {},
    searchDokter (val) {
      if (val) {
        // eslint-disable-next-line no-undef
        $.get(
          baseUrlV2 + 'poliklinik/dokter-list?q=' + val,
          (response) => {
            const { con, results } = response
            if (con) {
              this.items = results
            } else {
              console.log('DATA TIDAK DITEMUKAN')
            }
          },
          'JSON'
        )
      }
      // apiKedua
      //   .get('emr/icd?q=' + val, {})
      //   .then((response) => {
      //     // this.isLoading = false
      //     this.items = response.data.results
      //   })
      //   .catch(function (error) {
      //     // handle error
      //     console.log(error)
      //   })
      //   .then(function () {
      //     // always executed
      //   })
    }
  }
}
</script>
