<template>
  <div>
    <v-autocomplete
      v-model="vModel"
      return-object
      item-text="id"
      item-value="text"
      multiple
      outlined
      dense
      deletable-chips
      :cache-items="true"
      :disabled="isReadonly"
      placeholder="Start typing to Search"
      :items="items"
      chips
      :search-input.sync="searchIcd"
      :label="label"
      @change="handleChange"

    >
    </v-autocomplete>
    <!-- {{ vModel }} -->
    <div v-if="vModel">
      <v-chip
        v-for="(indexIcd, i) of vModel"
        :key="i"
        class="ma-2"
        close
        color="red"
        @click:close="deleteItem(i)"
        text-color="white"
      >
        {{ indexIcd.text }}
      </v-chip>
    </div>
  </div>
</template>
<script>
import {
  apiKedua,
  // eslint-disable-next-line no-unused-vars
  successMsg,
  // eslint-disable-next-line no-unused-vars
  baseUrl,
  // eslint-disable-next-line no-unused-vars
  baseUrlV2,
  // eslint-disable-next-line no-unused-vars
  errorMsg
} from '@/plugins/supports'
export default {
  name: 'AutoComplete',
  props: {
    property: { type: [Array, Object], required: false, default: () => {} },
    cvalue: {
      type: [Array, Object, String, Function, Boolean],
      required: false,
      default: () => null
    },
    syncValue: {
      type: Function,
      default: (v) => v,
      required: true
    },
    rules: { type: [Array], required: false, default: () => [] },
    label: { type: [String, Boolean], required: false, default: () => '' },
    vclass: { type: String, required: false, default: () => '' }
  },
  created () {
    const { attrs, events } = this.property || {}
    this.attrs = attrs || {}
    this.events = events || {}
    this.isArray = this.vModel
  },
  data () {
    return {
      searchIcd: '',
      modelicd: '',
      isReadonly: false,
      isLoading: false,
      isArray: true,
      vModel: null,
      dataVmodel: null,
      attrs: {},
      events: {},
      pegawai: '',
      select: { state: 'Florida', abbr: 'FL' },
      items: []
    }
  },
  methods: {
    deleteItem (index) {
      // console.log('hantu' + index)
      this.vModel.splice(index, 1)
      // this.vModel.splice(index, 1)
    },
    cekInputan () {},
    handleChange (selectedItems) {
      if (Array.isArray(selectedItems)) {
        this.vModel = selectedItems
      }
    }
  },
  mounted () {
    // this.$emit('input', this.vModel)
    // console.log(this.vModel + 'ADA NGGAK ISINYA')
    // this.pegawai = JSON.parse(localStorage.getItem('user'))
    // console.log(this.pegawai.sub_unit_id + '  DATA PEGAWAI')
  },
  watch: {
    vModel (n, o) {
      this.syncValue(n)
    },
    cvalue (n, o) {
      // console.log(n + 'INI NGGAK ADA ISINYA ???')
      this.vModel = n
    },
    emitInput () {},
    searchIcd (val) {
      // console.log(this.$route.query.sub_unit_id)
      if (val) {
        apiKedua
          .get(
            'emr/icd?q=' + val + '&sub_unit_id=' + this.$route.query.sub_unit_id,
            {}
          )
          .then((response) => {
          // this.isLoading = false
            this.items = response.data.results
          })
          .catch(function (error) {
          // handle error
            console.log(error)
          })
          .then(function () {
          // always executed
          })
      }
    }
  }
}
</script>
