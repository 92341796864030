<template>
  <div :class="vclass">
    <h6 class="mt-3">{{ label }}</h6>
  </div>
</template>
<script>
import { isEmpty } from '@/plugins/supports'
export default {
  props: {
    data: { type: [Array, Object, String], required: true, default: () => [] },
    property: { type: [Array, Object], required: true, default: () => [] },
    cvalue: {
      type: [Array, Object, String, Function, Boolean],
      required: false,
      default: () => null
    },
    syncValue: {
      type: Function,
      default: (v) => v,
      required: true
    },
    rules: { type: [Array], required: false, default: () => [] },
    label: { type: [String, Boolean], required: false, default: () => '' },
    vclass: { type: String, required: false, default: () => '' }
  },
  created () {
    this.attrsRadio = !isEmpty(this.property)
      ? this.property.attrsRadio || []
      : []
    this.attrsInput = !isEmpty(this.property)
      ? this.property.attrsInput || []
      : []
    const { attrs, events } = this.property || {}
    this.attrs = attrs || { row: true }
    this.events = events || {}
  },
  data () {
    return {
      vModel: null,
      attrsRadio: [],
      attrsInput: [],
      attrs: {},
      events: {},
      isLain: false,
      optionSelected: null
    }
  }
}
</script>
<style scoped>
.c-txtf /deep/ input {
  padding: 1px 0 2px !important;
}
</style>
