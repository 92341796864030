<template>
  <div>
    <div v-if="!!property.label" class="mb-2">
      <label class="v-label font-weight-bold">{{ property.label }}</label>
    </div>
    <v-text-field
      v-model="vModel"
      :label="!property.label ?label:null"
      :rules="rules"
      :class="vclass"
      outlined
      dense
      :disabled="disabled"

      hide-details="auto"
      v-bind="attrs"
      v-on="events"
      type="date"
    />
  </div>
</template>
<script>
export default {
  name: 'TextField',
  props: {
    disabled: { type: Boolean, required: false, default: () => false },
    property: { type: [Array, Object], required: false, default: () => {} },
    cvalue: {
      type: [Array, Object, String, Function, Boolean],
      required: false,
      default: () => null
    },
    syncValue: {
      type: Function,
      default: (v) => v,
      required: true
    },
    rules: { type: [Array], required: false, default: () => [] },
    label: { type: [String, Boolean], required: false, default: () => '' },
    vclass: { type: String, required: false, default: () => '' }
  },
  created () {
    const { attrs, events } = this.property || {}
    this.attrs = attrs || {}
    this.events = events || {}
  },
  data () {
    return {
      vModel: null,
      attrs: {},
      events: {}
    }
  },
  watch: {
    vModel (n, o) {
      this.syncValue(n)
    },
    cvalue (n, o) {
      this.vModel = n
    }
  }
}
</script>
