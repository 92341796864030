<template>
  <div :class="vclass">
    <div v-if="!!label && label !== 'null'">
      <label class="v-label font-weight-bold">{{ label }}</label>
    </div>
    <div v-for="(v, i) in Object.keys(datas)" :key="i" style="display: grid">
      <div v-if="v !== 'none'" class="pb-2">
        <div
          style="display: inline-flex; width: 100%"
          v-if="property.numbering"
        >
          <label class="v-label font-weight-bold" style="width: 50px !important"
            >{{ getTotals(v) }}.
          </label>
          <label class="v-label font-weight-bold">{{ v }}</label>
        </div>
        <div style="padding-left: 50px">
          <div v-for="(d, j) in datas[v]" :key="j">
            <v-checkbox
              v-model="vModel"
              :label="d.text"
              :value="d"
              :rules="rules"
              :class="`mt-0 float-left pr-3`"
              multiple
              hide-details="auto"
              v-bind="attrsCheckbox"
              v-on="eventsCheckbox"
            />
            <v-textarea
              v-model="d.value"
              v-if="lainSelected(d)"
              :class="`pt-0 mt-2 c-txtf`"
              :rules="rules"
              hide-details="auto"
              auto-grow
              outlined
              dense
              rows="1"
              placeholder="Sebutkan dan Reaksinya"
              @keydown="autoCheck(d)"
              v-bind="attrsInput"
              v-on="eventsInput"
            />
          </div>
        </div>
      </div>
      <template v-else>
        <div
          v-for="(d, k) in datas[v]"
          :key="k"
          class="pb-2"
          style="display: inline-flex; width: 100%"
        >
          <label
            v-if="property.numbering"
            class="v-label font-weight-bold"
            style="
              min-width: 50px !important;
              align-self: center;
              padding-right: 15px;
            "
            >{{ k + i + property.startNum }}.
          </label>
          <v-checkbox
            v-model="vModel"
            :label="d.text"
            :value="d"
            :rules="rules"
            :class="`mt-0 pt-0`"
            :style="`align-self: center;min-width: ${countWidth(d.text)}px;`"
            multiple
            hide-details="auto"
            v-bind="attrsCheckbox"
            v-on="eventsCheckbox"
          />
          <v-textarea
            v-model="d.value"
            v-if="lainSelected(d)"
            :class="`pl-15 pt-0 mt-0 c-txtf`"
            style="align-self: center"
            :rules="rules"
            hide-details="auto"
            auto-grow
            dense
            rows="1"
            placeholder=". . . . . . . . . . . . "
            @keydown="autoCheck(d)"
            v-bind="attrsInput"
            v-on="eventsInput"
          />
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import { isEmpty } from '@/plugins/supports'
export default {
  props: {
    data: { type: [Array, Object], required: true, default: () => [] },
    property: { type: [Array, Object], required: true, default: () => [] },
    cvalue: {
      type: [Array, Object, String, Function, Boolean],
      required: false,
      default: () => null
    },
    syncValue: {
      type: Function,
      default: (v) => v,
      required: true
    },
    rules: { type: [Array], required: false, default: () => [] },
    label: { type: [String, Boolean], required: false, default: () => '' },
    vclass: { type: String, required: false, default: () => '' }
  },
  created () {
    const { attrsCheckbox, attrsInput, eventsCheckbox, eventsInput } =
      this.property || {}
    this.attrsCheckbox = attrsCheckbox || {}
    this.attrsInput = attrsInput || {}
    this.eventsCheckbox = eventsCheckbox || {}
    this.eventsInput = eventsInput || {}
    this.genGroup()
    this.setupDatas()
  },
  data () {
    return {
      vModel: null,
      attrsCheckbox: {},
      attrsInput: {},
      eventsCheckbox: {},
      eventsInput: {},
      numberList: 0,
      datas: {},
      totals: {}
    }
  },
  watch: {
    vModel (n, o) {
      this.syncValue(n)
    },
    cvalue (n, o) {
      this.matchingValue(n)
      this.vModel = n
    }
  },
  methods: {
    matchingValue (n) {
      for (const data in this.datas) {
        for (const data2 in this.datas[data]) {
          const tmp = this.datas[data][data2]
          for (const data3 in n) {
            const tmp2 = n[data3]
            if (tmp2.text.toLowerCase() === tmp.text.toLowerCase()) {
              this.datas[data][data2] = tmp2
            }
          }
        }
      }
    },
    genGroup () {
      const tmp = {}
      for (const v of this.data) {
        if (
          typeof tmp[typeof v.group !== 'undefined' ? v.group : 'none'] !==
          'undefined'
        ) {
          tmp[typeof v.group !== 'undefined' ? v.group : 'none'].push(v)
        } else {
          tmp[typeof v.group !== 'undefined' ? v.group : 'none'] = [v]
        }
      }
      this.datas = tmp
    },
    lainSelected (v) {
      if (v.field === 'show') {
        return true
      }
      if (v.field === 'auto') {
        if (JSON.stringify(this.vModel).includes(v.text)) {
          return true
        }
      }
      return false
    },
    autoCheck (v) {
      if (!JSON.stringify(this.vModel).includes(v.text)) {
        if (this.vModel === null) {
          this.vModel = []
        }
        if (!isEmpty(v)) {
          this.vModel.push(v)
        }
      }
    },
    checkLabel (v) {
      if (typeof v.label === 'undefined') {
        return false
      }
      if (v.label !== null) {
        return v.label
      }
      return false
    },
    getLabel (v) {
      return v.label ?? this.label
    },
    checkGroupLabel (v, i) {
      if (this.property.grouping !== true) {
        return false
      }
      // console.log(i, v.group, this.data[i - 1].group)
      if (typeof v.group === 'undefined') {
        return false
      }
      if (v.group === '' || v.group === false) {
        return false
      }
      if (i !== 0) {
        if (v.group === this.data[i - 1].group) {
          return false
        }
      }
      return true
    },
    checkGroupLabelNull (v, i) {
      if (this.property.grouping !== true) {
        return false
      }
      // console.log(i, v.group, this.data[i - 1].group)
      if (typeof v.group === 'undefined') {
        return true
      }
      if (v.group === '' || v.group === false) {
        return false
      }
      if (i !== 0) {
        if (v.group === this.data[i - 1].group) {
          return false
        }
      }
      return false
    },
    setupDatas () {
      if (this.property.numbering !== true) {
        return ''
      }

      for (const key in this.datas) {
        if (key === 'none') {
          this.totals[key] = this.datas[key].length
        } else {
          this.totals[key] = 1
        }
      }
    },
    getTotals (v) {
      let counter = 0
      for (const key in this.totals) {
        counter = counter + this.totals[key]
        if (key === v) {
          break
        }
      }
      return counter + (this.property.numbering ? this.property.startNum - 1 : 0)
    },
    countWidth (text) {
      return text ? (text.length > 200 ? 200 : text.length * 5) : 0
    }
  }
}
</script>
<style scoped>
.c-txtf /deep/ input {
  padding: 1px 0 2px !important;
}
</style>
