<template>
  <div :class="vclass">
    <label class="v-label font-weight-bold">{{ label }}</label>
    <v-radio-group
      v-model="vModel"
      :rules="rules"
      class="pt-0 pb-0 mt-0 mb-0"
      hide-details="auto"
      v-bind="attrs"
      v-on="events"
    >
      <div v-for="(v, i) in data" :key="i">
        <v-radio
          :label="v.text"
          :value="v"
          :rules="rules"
          :class="`${vclass} ${v.float ? 'float-left' : ''} mt-2`"
          v-bind="attrsRadio"
        />
        <v-text-field
          :ref="`txtf_${i}`"
          v-model="v.value"
          v-if="lainSelected(v,i)"
          :class="`${v.float ? 'float-left' : 'pl-7'} pt-0 mt-2 c-txtf`"
          v-bind="attrsInput"
          :rules="rules"
          hide-details="auto"
          dense
        />
        <div v-if="v.option && lainSelected(v,i)">
          <v-btn
            v-for="item in v.option"
            v-bind:key = item
            @click="setValueTemp(v.valueOption,item)"
            :outlined="optionSelected===item"
            :text="optionSelected!==item"
            color="primary"
            :class="`pt-0 mt-2`"
          >{{item}}</v-btn>
        </div>
      </div>
    </v-radio-group>
  </div>
</template>
<script>
import { isEmpty } from '@/plugins/supports'
export default {
  props: {
    data: { type: [Array, Object], required: true, default: () => [] },
    property: { type: [Array, Object], required: true, default: () => [] },
    cvalue: {
      type: [Array, Object, String, Function, Boolean],
      required: false,
      default: () => null
    },
    syncValue: {
      type: Function,
      default: (v) => v,
      required: true
    },
    rules: { type: [Array], required: false, default: () => [] },
    label: { type: [String, Boolean], required: false, default: () => '' },
    vclass: { type: String, required: false, default: () => '' }
  },
  created () {
    this.attrsRadio = !isEmpty(this.property)
      ? this.property.attrsRadio || []
      : []
    this.attrsInput = !isEmpty(this.property)
      ? this.property.attrsInput || []
      : []
    const { attrs, events } = this.property || {}
    this.attrs = attrs || { row: true }
    this.events = events || {}
  },
  data () {
    return {
      vModel: null,
      attrsRadio: [],
      attrsInput: [],
      attrs: {},
      events: {},
      isLain: false,
      optionSelected: null
    }
  },
  watch: {
    vModel (n, o) {
      this.syncValue(n)
    },
    cvalue (n, o) {
      this.matchingValue(n)
      this.vModel = n
    }
  },
  methods: {
    matchingValue (n) {
      for (const data in this.data) {
        const tmp = this.data[data]
        if (n.text.toLowerCase() === tmp.text.toLowerCase()) {
          this.data[data] = n
          break
        }
      }
    },
    setValueTemp (id, value) {
      // emitter.emit('setradiooption', id, value)
      this.$root.$emit('setradiooption', [id, value])
      this.optionSelected = value
    },
    lainSelected (v, i) {
      if (v.field === 'show') {
        return true
      }
      if (v.field === 'auto') {
        if (JSON.stringify(this.vModel).includes(v.text)) {
          // eslint-disable-next-line dot-notation
          var tmp = this.$refs
          console.log('_______________________')
          // console.log(this)
          // console.log(tmp.txtf_1[0])
          console.log('txtf_' + i, tmp['txtf_' + i])
          // tmp['txtf_' + i].focus()
          // if (this.isLain === true) {
          // if (this.isLain === false) {
          //   alert('sdsd')
          //   setTimeout(() => {
          //     // console.log('txtf_' + i, tmp['txtf_' + i][0])
          //     tmp['txtf_' + i][0].focus()
          //   }, 100)
          // }
          return true
        }
      }
      return false
    }
  }
}
</script>
<style scoped>
.c-txtf /deep/ input {
  padding: 1px 0 2px !important;
}
</style>
