<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="vModel"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="computedDateFormatted"
        :label="label"
        readonly
        v-on="Object.assign(on,events)"
        dense
        :rules="rules"
        :class="vclass"
        hide-details="auto"
        v-bind="Object.assign(attrs,attrs2)"
      ></v-text-field>
    </template>
    <v-date-picker :show-current="false" v-model="vModel" no-title scrollable locale="id-ID">
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
      <v-btn text color="primary" @click="$refs.menu.save(vModel)"> OK </v-btn>
    </v-date-picker>
  </v-menu>
</template>
<script>
export default {
  props: {
    property: { type: [Array, Object], required: false, default: () => {} },
    cvalue: {
      type: [Array, Object, String, Function, Boolean],
      required: false,
      default: () => null
    },
    syncValue: {
      type: Function,
      default: (v) => v,
      required: true
    },
    rules: { type: [Array], required: false, default: () => [] },
    label: { type: [String, Boolean], required: false, default: () => '' },
    vclass: { type: String, required: false, default: () => '' }
  },
  created () {
    const { attrs, events } = this.property || {}
    this.events = events || {}
    this.attrs2 = attrs || {}
  },
  data: () => ({
    menu: false,
    attrs2: {},
    vModel: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10)
  }),
  watch: {
    vModel (n, o) {
      this.syncValue(n)
    },
    cvalue (n, o) {
      this.vModel = n
    }
  },
  computed: {
    computedDateFormatted () {
      return this.formatDate(this.vModel)
    }
  },
  methods: {
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${month}/${day}/${year}`
    },
    parseDate (date) {
      if (!date) return null

      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    }
  }
}
</script>
<style scoped>
.c-text-field /deep/ .v-label {
  text-transform: capitalize !important;
}
</style>
