<template>
  <div :class="vclass">
    <label v-if="label !== ' '" class="v-label font-weight-bold">{{ label }}</label>
    <v-radio-group
      v-model="vModel"
      :rules="rules"
      :disabled="disabled"
      class="pt-0 pb-0 mt-0 mb-0"
      hide-details="auto"
      v-bind="attrs"
      v-on="events"
    >
      <div v-for="(v, i) in data" :key="i">
        <v-radio
          :label="v.text"
          :value="v"
          :rules="rules"
          :class="`${vclass} ${v.float ? 'float-left' : ''} mt-2`"
          v-bind="attrsRadio"
        />
        <v-text-field
          :ref="`txtf_${i}`"
          v-model="v.value"
          v-if="lainSelected(v, i)"
          :class="`${v.float ? 'float-left' : 'pl-7'} pt-0 mt-2 c-txtf`"
          v-bind="attrsInput"
          :rules="rules"
          hide-details="auto"
          dense
        />
      </div>
    </v-radio-group>
  </div>
</template>

<script>
import { isEmpty } from '@/plugins/supports'

export default {
  props: {
    disabled: { type: Boolean, required: false, default: () => false },
    data: { type: [Array, Object], required: true, default: () => [] },
    property: { type: [Array, Object], required: true, default: () => [] },
    cvalue: {
      type: [Array, Object, String, Function, Boolean],
      required: false,
      default: () => null
    },
    syncValue: {
      type: Function,
      default: (v) => v,
      required: true
    },
    rules: { type: [Array], required: false, default: () => [] },
    label: { type: [String, Boolean], required: false, default: () => '' },
    vclass: { type: String, required: false, default: () => '' }
  },
  created () {
    this.attrsRadio = !isEmpty(this.property) ? this.property.attrsRadio || [] : []
    this.attrsInput = !isEmpty(this.property) ? this.property.attrsInput || [] : []
    const { attrs, events } = this.property || {}
    this.attrs = attrs || { row: true }
    this.events = events || {}
  },
  data () {
    return {
      vModel: this.cvalue || null,
      attrsRadio: [],
      attrsInput: [],
      attrs: {},
      events: {},
      isLain: false
    }
  },
  watch: {
    vModel (n) {
      this.syncValue(n)
    },
    cvalue (n) {
      this.matchingValue(n)
      this.vModel = n
    }
  },
  methods: {
    matchingValue (n) {
      for (const data of this.data) {
        if (n?.text?.toLowerCase() === data?.text?.toLowerCase()) {
          Object.assign(data, n)
          break
        }
      }
    },
    lainSelected (v, i) {
      if (v.field === 'show') {
        return true
      }
      if (v.field === 'auto') {
        if (JSON.stringify(this.vModel).includes(v.text)) {
          return true
        }
      }
      return false
    }
  }
}
</script>

<style scoped>
.c-txtf /deep/ input {
  padding: 1px 0 2px !important;
}
</style>
