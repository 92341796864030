<template>
  <component
    :is="chooseComponent(comp)"
    :data="data"
    :label="label"
    :rules="rules"
    :cvalue="value"
    :sync-value="syncValue"
    :vclass="vclass"
    :disabled="disabled"
    :property="property"
  />
</template>
<script>
import TextField from '../components/TextField.vue'
import TextareaField from '../components/TextareaField.vue'
import TextareaFieldRow from '../components/TextareaFieldRow.vue'
import DatePicker from '../components/DatePicker.vue'
import DatePickerNormal from '../components/DatePickerNormal.vue'
import RadioGroup from '../components/RadioGroup.vue'
import RadioGroupWithOption from '../components/RadioGroupWithOption.vue'
import CheckboxGroup from '../components/CheckboxGroup.vue'
import SelectWidget from '../components/SelectWidget.vue'
import AutoWidget from '../components/AutoComplete.vue'
import AutoWidgetKeluhan from '../components/AutoCompleteKeluhan.vue'
import AutoWidgetTindakan from '../components/AutoCompleteTindakan.vue'
import AutoWidgetSingle from '../components/AutoCompleteSingle.vue'
import LabelKiri from '../components/LabelKiri.vue'
import TimeField from '../components/TimeField.vue'

export default {
  name: 'SmartWidget',
  components: {
    TextField,
    TextareaFieldRow,
    DatePicker,
    DatePickerNormal,
    RadioGroup,
    RadioGroupWithOption,
    SelectWidget,
    CheckboxGroup,
    TextareaField,
    AutoWidget,
    AutoWidgetSingle,
    AutoWidgetTindakan,
    LabelKiri,
    TimeField,
    AutoWidgetKeluhan
  },

  props: {
    comp: { type: String, required: true, default: () => '' },
    data: {
      type: [Array, Object, String],
      required: false,
      default: () => null
    },
    property: { type: [Array, Object], required: false, default: () => [] },
    value: {
      type: [Array, Object, String, Function, Boolean],
      required: false,
      default: () => null
    },
    syncValue: {
      type: Function,
      default: (v) => v,
      required: true
    },
    rules: { type: [Array], required: false, default: () => [] },
    label: { type: [String, Boolean], required: false, default: () => '' },
    disabled: { type: [String, Boolean], required: false, default: () => false },
    vclass: { type: String, required: false, default: () => '' }
  },
  created () {
    // console.log(this.comp, ' => ', this.widgets[this.comp])
  },
  data () {
    return {
      widgets: {
        wtext: 'text-field',
        wtextarea: 'textarea-field',
        wtextarearow: 'textarea-field-row',
        wdatenormal: 'date-picker-normal',
        wdate: 'date-picker',
        wradio: 'radio-group',
        wradiowithoption: 'radio-group-with-option',
        wselect: 'select-widget',
        wcheckbox: 'checkbox-group',
        wautocomplate: 'auto-widget',
        wautocomplatesingle: 'auto-widget-single',
        wautocomplatetindakan: 'auto-widget-tindakan',
        wautocomplatekeluhan: 'auto-widget-keluhan',
        wlabelkiri: 'label-kiri',
        wtime: 'time-field'
      }
    }
  },
  methods: {
    chooseComponent (item) {
      return this.widgets[item]
    }
  }
}
</script>
<style scoped>
.c-text-field /deep/ .v-label {
  text-transform: capitalize !important;
}
</style>
